
import Vue from 'vue';
import Component from 'vue-class-component';
import LayoutStore from '@/core/store/modules/Layout';
import MenuStore from '@/core/store/modules/Menu';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import RecursiveMenu from '@/general/infrastructure/shared/RecursiveMenu.vue';

@Component({
  name: 'Sidebar',
  components: {
    RecursiveMenu
  }
})
export default class Sidebar extends Vue {
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly companyFindById!: CompanyFindById;
  //Data
  urlLogoCompany = '';
  username = localStorage.getItem('userNameCompany') || '';
  width = 0;
  height = 0;
  margin = 0;
  widthLogoCompany = 0;
  hideRightArrowRTL = true;
  hideLeftArrowRTL = true;
  hideRightArrow = true;
  hideLeftArrow = true;
  menuWidth = 0;
  temp = 0;

  //Hook
  async created() {
    await MenuStore.getMenu();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    if (this.width < 991) {
      this.layout.settings.sidebar.type = 'default';
    }
    const val = this.width <= 540 ? 'horizontal' : this.sidebar;
    if (val == 'default') {
      this.layout.settings.sidebar.type = 'default';
      this.layout.settings.sidebar.bodyType = 'default';
      this.widthLogoCompany = 200;
    } else if (val == 'compact') {
      this.layout.settings.sidebar.type = 'compact-wrapper';
      this.layout.settings.sidebar.bodyType = 'sidebar-icon';
      this.widthLogoCompany = 110;
    } else if (val == 'compact-icon') {
      this.layout.settings.sidebar.type = 'compact-page';
      this.layout.settings.sidebar.bodyType = 'sidebar-hover';
      this.widthLogoCompany = 60;
    } else if (val == 'horizontal') {
      this.layout.settings.sidebar.type = 'horizontal_sidebar';
      this.layout.settings.sidebar.bodyType = '';
      this.widthLogoCompany = 200;
    }
    setTimeout(() => {
      const element: any = document.getElementById('myDIV');
      this.menuWidth = element.offsetWidth;
      this.menuWidth > window.innerWidth
        ? ((this.hideRightArrow = false), (this.hideLeftArrowRTL = false))
        : ((this.hideRightArrow = true), (this.hideLeftArrowRTL = true));
    }, 500);
  }
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }
  mounted() {
    this.$nextTick(() => this.logoCompany());
    this.menuItems.filter((items: any) => {
      if (items.path === this.$route.path) MenuStore.setActiveRouteAct(items);
      if (!items.children) return false;
      items.children.filter((subItems: any) => {
        if (subItems.path === this.$route.path) MenuStore.setActiveRouteAct(subItems);
        if (!subItems.children) return false;
        subItems.children.filter((subSubItems: { path: string }) => {
          if (subSubItems.path === this.$route.path) MenuStore.setActiveRouteAct(subSubItems);
        });
      });
    });
  }
  //Property Computed
  get layout() {
    return LayoutStore.layout;
  }
  get menuItems() {
    return MenuStore.data;
  }
  get sidebar() {
    return LayoutStore.sidebarType;
  }
  get rol() {
    return (localStorage.getItem('userRol') as string).replace('ROLE_', '');
  }
  get userName() {
    return (localStorage.getItem('userNameCompany') as string).toUpperCase();
  }
  get logo() {
    return process.env.VUE_APP_URL_TOKEN + `/${localStorage.getItem('logoUser')}`;
  }
  //Methods
  logoCompany() {
    const id = localStorage.getItem('businessId') as string;
    this.companyFindById.internalExecute(id).then((response: any) => {
      this.urlLogoCompany = process.env.VUE_APP_URL_TOKEN + '/file/getImageAsByteArray?name=' + response['urlLogo'];
      localStorage.setItem('urlLogoCompany', this.urlLogoCompany);
    });
  }
  setNavActive(item: any) {
    this.$store.dispatch('Menu/setNavActiveAct', item);
  }
  handleResize() {
    this.width = window.innerWidth - 310;
  }
  scrollToRightRTL() {
    this.temp = this.width + this.margin;
    if (this.temp === 0) {
      this.margin = this.temp;
      this.hideRightArrowRTL = true;
    } else {
      this.margin += this.width;
      this.hideRightArrowRTL = false;
      this.hideLeftArrowRTL = false;
    }
  }
  scrollToLeftRTL() {
    // If Margin is reach between screen resolution
    if (this.margin <= -this.width) {
      this.margin += -this.width;
      this.hideLeftArrowRTL = true;
    }
    //Else
    else {
      this.margin += -this.width;
      this.hideRightArrowRTL = false;
    }
  }
  scrollToLeft() {
    // If Margin is reach between screen resolution
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.hideLeftArrow = true;
    }
    //Else
    else {
      this.margin += this.width;
      this.hideRightArrow = false;
    }
  }
  scrollToRight() {
    this.temp = this.menuWidth + this.margin;
    if (this.temp < this.menuWidth) {
      this.margin = -this.temp;
      this.hideRightArrow = true;
    }
    // else
    else {
      this.margin += -this.width;
      this.hideLeftArrow = false;
    }
  }
}
